// import Vue from 'vue'
import { Component, Watch } from "vue-property-decorator";
import baseUserContextPage from "../baseUserContextPage";
import axios, { AxiosPromise } from "axios";
import { pagedList, sortDirection } from "@/models/pagedList";
import { EmployeeModel } from "@/models/employeeModels";

@Component({})
export default class employeesPage extends baseUserContextPage {
  isLoading: boolean = false;
  list: pagedList<EmployeeModel> = new pagedList();
  searchParams: any = {
    pageSize: 20,
    pageIndex: 0,
    orderField: "name",
    direction: sortDirection.ASC,
    search: null
  };
  get showReferent(): boolean {
    return this.isUserB2BAdmim;
  }

  updateQueryParams(): void {
    var instance = this;
    instance.$router.push({
      name: "employees",
      query: instance.getQueryParams()
    });
    this.fetch();
  }
  getQueryParams(): any {
    var instance = this;
    return {
      page: instance.searchParams.pageIndex,
      search: instance.searchParams.search,
      orderField: instance.searchParams.orderField,
      direction: instance.searchParams.direction
    };
  }
  setQueryParams(params: any) {
    var instance = this;
    instance.searchParams.pageIndex = params.page;
    instance.searchParams.search = params.search;
    instance.searchParams.orderField = params.orderField;
    instance.searchParams.direction = params.direction;
  }
  pageChanged(page: number) {
    this.searchParams.pageIndex = page;
    this.updateQueryParams();
  }
  onSearch() {
    this.searchParams.pageIndex = 0;
    this.updateQueryParams();
  }
  onSort(field: string) {
    var instance = this;
    if (instance.searchParams.orderField != field) {
      instance.searchParams.orderField = field;
      instance.searchParams.direction = sortDirection.ASC;
    } else {
      instance.searchParams.direction =
        instance.searchParams.direction == sortDirection.ASC
          ? sortDirection.DESC
          : sortDirection.ASC;
    }
    instance.updateQueryParams();
    //instance.fetch();
  }
  fetch(): void {
    var instance = this;
    instance.isLoading = true;
    axios
      .get<pagedList<EmployeeModel>>("/employee/search", {
        params: instance.searchParams
      })
      .then(res => {
        instance.list = res.data ? res.data : new pagedList();
        instance.isLoading = false;
      })
      .catch(error => {
        console.error(error);
        instance.isLoading = false;
      });
  }
  mounted() {
    var instance = this;
    instance.setQueryParams(instance.$route.query);
    instance.fetch();
  }
}
